<script>
import { Bar } from "vue-chartjs";

export default {
  extends: Bar,
  props: ["dataSource", "percentage"],
   computed: {
    displayData() {
      let numberData = this.chartData.map(element => {
        return element.value;
      });

      return numberData;
    },
    labels() {
      let labels = this.chartData.map(element => {
        return element.name;
      });
      return labels;
    },
    backgroundColors() {
      let colors = this.chartData.map(element => {
        return element.color;
      });
      return colors;
    },
  },
   data() {
    return {
      chartData: []
    };
  },
  watch: {
    dataSource(val, oldval) {
      this.chartData = val;
      this.renderTheChart();
    }
  },
   methods: {
    maxValue() {
      const countMax = Math.max(...this.displayData)

      return this.percentage ? 100 : countMax + 1
    },
    stepValue() {
      return this.percentage ? 10 : 1
    },
    renderTheChart() {
      if (this.$data._chart) {
        this.$data._chart.destroy();
      }

      this.renderChart(
        {
          labels: this.labels,
          datasets: [
            {
              backgroundColor: this.backgroundColors,
              data: this.displayData
            }
          ]
        },
        {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            xAxes: [
              {
                gridLines: {
                  color: "#e6ecf5"
                }
              }
            ],
            yAxes: [
              {
                categorySpacing: 0,
                categoryPercentage: 1,
                barPercentage: 1,
                ticks: {
                  beginAtZero: true,
                  min: 0,
                  max: this.maxValue() ,
                  stepSize: this.stepValue()
                },
                gridLines: {
                  color: "#e6ecf5",
                  lineWidth: 0.4
                },
                              scaleLabel: {
                display: true,
                labelString: this.percentage ? 'Percentage' : 'Count',
              },
              },

            ]
          },
          legend: {
            display: false
          },
        });
    },
  },
  mounted () {
     this.chartData = this.dataSource;
     this.renderTheChart();
    // Overwriting base render method with actual data.
    // this.renderChart({
    //   labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
    //   datasets: [
    //     {
    //       label: '',
    //       backgroundColor: '#3c8dbc',
    //       data: [12, 20, 12, 18, 10, 6, 9, 32, 29, 19, 12, 11]
    //     }
    //   ]
    // },)
  }
};
</script>

<style scoped>
</style>
